.o-amount{
    text-align: center;
    &--payment{
        font-weight: 700;
        font-size: 3.125rem;
        @media (max-width:404px) {
            font-size: 2rem;
          }; 
    }
    &--currency{
        font-size: 1.5rem;
        font-weight: 700;
        margin-left: 0.4rem;
    }
    &--exchange{
        display: flex;
        grid-gap: #{(14/16)}rem; //14px
        justify-content: center;
        &-amount {
            text-align: left;
            font-size: #{(24/16)}rem; //24px
            font-weight: 600;
            .o-amount--currency {
                font-weight: 600;
                font-size: #{(14/16)}rem; //14px
                margin-left: 0.4rem;
            }
        }
        &-rate {
            font-size: #{(10/16)}rem; //10px
            &-amount {
                font-size: #{(14/16)}rem; //14px
                line-height: 1;
            }
            .o-amount--currency {
                font-size: inherit;
                font-weight: inherit;

            }
        }
    }
}
